import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import $ from "jquery";
import { Link } from "gatsby";
import { navigate } from "@reach/router"
import StaticUrls from "@components/common-data/static-urls";
/**
 * Component
 */

import { SearchIcon } from '@components/icon/icon';

/**
 * Assets
 */

import './styles/_index.scss';

const HomeSearch = (props) => {

    const handleSearch = (values, type) => {
        let url = "/";
        const searchValue = values.search.trim();
        if (searchValue !== "") {
            url = `in-${searchValue}/`;
        } else {
            url = "in-london/";
        }
        navigate(`/property/${type}/${url}`);
        window.scrollTo(0, 0);
    };

    return (
        <Formik
            initialValues={{ search: '' }}
            validate={values => {
                const errors = {};
                if (!values.search) {
                errors.search = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className="home-search m-0">
                    {/* <Form.Group className="m-0">
                        <div className="icon-search"><SearchIcon /></div>
                        <Form.Control type="text" className="search-field-text text-18" placeholder="Enter area or postcode" name="search" onChange={handleChange} onBlur={handleBlur} value={values.search} />
                    </Form.Group> */}
                    <div className="btns">
                        <button type="submit" className="sales-btn btn btn-transparent-black" onClick={() => {
                                setSubmitting(true);
                                handleSearch(values, 'for-sale');
                                setSubmitting(false);
                            }} disabled={isSubmitting}>
                         <span className="d-flex d-lg-none">Buy</span><span className="d-none d-lg-flex">For Sale</span>
                        </button>
                        <button type="submit" className="rent-btn btn btn-transparent-black" onClick={() => {
                                setSubmitting(true);
                                handleSearch(values, 'to-rent');
                                setSubmitting(false);
                            }} disabled={isSubmitting}>
                        <span className="d-flex d-lg-none">Rent</span><span className="d-none d-lg-flex">To Rent</span>
                        </button>
                        <Link to={StaticUrls.ValuationPage} className="btn btn-transparent-black">Valuation</Link>
                    </div>
                </form>
            )}
        </Formik> 
    )
}

export default HomeSearch